// fetchBindOAuth.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getOperationData from '../selector/getOperationData.js';
import getMeData from '../selector/getMeData.js';
import getRegisterData from '../selector/getRegisterData.js';
import removeModals from '../action/removeModals.js';
import pushToastr from '../action/pushToastr.js';
import fetchAccountLinks from '../action/fetchAccountLinks.js';
import transitRegisterEmailLogout from '../action/transitRegisterEmailLogout.js';
import closeEmailBindingAlert from '../action/closeEmailBindingAlert.js';
import fetchAccessToken, { viaTypes } from '../action/fetchAccessToken.js';
import proceedJoinStep from '../action/proceedJoinStep.js';
import updateRegisterData from '../action/updateRegisterData.js';

import openSetPasswordDirectorModal from '../action/openSetPasswordDirectorModal.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import {
  SET_NETWORKING_IDLE,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';
import {
  register as registerModal,
  login as loginModal,
} from '../resource/modalId.js';
import { BindEmail } from '../resource/authEntryType.js';
import { Progress as JoinProgress } from '../resource/joinConstants.js';
import addModal from './addModal.js';

const AUTH_IN_USE_ERROR_CODE = 'social_core_exceptions_authalreadyassociated';
const REQUIRE_LOGIN_CODE = 'require_login';

/**
 * Fetch bind o auth
 * @kind action
 * @param {string} {oAuth} - o auth key.
 * @param {object} {data} - data for specific oAuth.
 * @param {URL} {url} - url for oAuth.
 * @return {Promise} Action promise.
 */
const fetchBindOAuth =
  ({ oAuth, data, url }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    if (!token) {
      return { type: '' };
    }

    const selectPath = ['register', 'bind', oAuth];
    const fetchOptions = {
      method: 'POST',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    const authUrl = getResourceUrl({ endpoint: `/associate/${oAuth}` });

    if (url) {
      authUrl.search = url.search;
    }
    if ('email' === oAuth) {
      Object.keys(data).forEach(key => {
        if (key === 'isEmailAgreed') {
          return authUrl.searchParams.set('agree', data[key]);
        }
        authUrl.searchParams.set(key, data[key]);
      });
    } else if ('phone-number' === oAuth) {
      authUrl.searchParams.set('phone_number', data.e164PhoneNumber);
      authUrl.searchParams.set('pin', data.smsOtp);
    } else if ('password' === oAuth) {
      fetchOptions.headers['content-type'] = 'application/json';
      fetchOptions.body = JSON.stringify({
        username: data.username,
        password: data.password,
      });
    }

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      let response = await fetch(authUrl.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const currentUrl = new URL(location.href);
      const utm = getMeData(getState(), 'utm');
      if (utm) {
        Object.keys(utm)
          .filter(key => utm[key])
          .forEach(key => currentUrl.searchParams.set(key, utm[key]));
      }
      const replacedUrl = currentUrl.pathname + currentUrl.search;
      history.replaceState(null, '', replacedUrl);

      dispatch(
        removeModals({
          ids: [
            registerModal.EMAIL_VERIFY,
            registerModal.EMAIL_REFILL,
            registerModal.EMAIL_BIND_CONFIRM,
            loginModal.PHONE_VERIFY,
          ],
          transitionStatus: 'leaving',
        })
      );

      // 如果固定 remove PasswordSetting Modal, 會導致用 set password 綁定 email 後無法彈出 PasswordSetting Modal.
      if (oAuth === 'password') {
        dispatch(
          removeModals({
            ids: ['PasswordSetting'],
            transitionStatus: 'closing',
          })
        );
      }

      const bindEmailEntry =
        getOperationData(getState(), ['bindEmail'], 'bind-entry') || '';

      const isChangePhoneNumber = getRegisterData(
        getState(),
        'isChangePhoneNumber'
      );

      if (oAuth === 'email') {
        if (bindEmailEntry === BindEmail.PASSWORD_SETTING) {
          const { refresh_token: refreshToken } = await response.json();
          if (refreshToken) {
            await dispatch(
              fetchAccessToken({ refreshToken, via: viaTypes.JWT_LOGIN })
            );
          }
          dispatch(
            addModal({ id: 'PasswordSetting', transitionStatus: 'coming' })
          );
          dispatch(
            removeModals({
              ids: ['PasswordVerify'],
              transitionStatus: 'closing',
            })
          );
        }

        if (
          bindEmailEntry === BindEmail.ACCOUNT_LINKS ||
          bindEmailEntry === BindEmail.BINDING_ALERT
        ) {
          dispatch(openSetPasswordDirectorModal());
          dispatch(pushToastr({ textKey: 'email_bound_succeed' }));
        }

        dispatch(closeEmailBindingAlert());
      } else if (isChangePhoneNumber && oAuth === 'phone-number') {
        dispatch(
          updateRegisterData({ dataKey: 'isChangePhoneNumber', value: false })
        );
        dispatch(pushToastr({ textKey: 'change_successful' }));
      } else {
        dispatch(pushToastr({ textKey: 'link_account_success' }));
      }
      await dispatch(fetchAccountLinks());
      if ('email' === oAuth && bindEmailEntry === BindEmail.JOIN) {
        dispatch(
          proceedJoinStep({ progress: JoinProgress.PERSONAL_INFORMATION })
        );
      }

      const isPhoneBound = getMeData(getState(), 'isPhoneBound');
      const hasReferralCode =
        getOperationData(getState(), ['acquisition', 'manual'], 'code') ||
        getOperationData(getState(), ['acquisition', 'referral'], 'code');

      if (
        bindEmailEntry === BindEmail.REGISTER &&
        !isPhoneBound &&
        hasReferralCode
      ) {
        dispatch(
          addModal({ id: 'PhoneVerifyRewardModal', transitionStatus: 'coming' })
        );
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      let textKey = '';
      try {
        textKey = JSON.parse(error.message)
          .code.replace(/\W/g, '_')
          .toLowerCase();
        // eslint-disable-next-line no-empty
      } catch (_) {}

      if ('email' === oAuth && AUTH_IN_USE_ERROR_CODE === textKey) {
        dispatch(transitRegisterEmailLogout({ action: 'coming' }));
      }

      dispatch(pushToastr({ textKey, color: 'error' }));

      if ('password' === oAuth && REQUIRE_LOGIN_CODE === textKey) {
        dispatch(
          removeModals({
            ids: ['PasswordSetting'],
            transitionStatus: 'closing',
          })
        );
      }
      setTimeout(
        () => dispatch({ type: SET_NETWORKING_IDLE, payload: { selectPath } }),
        3000 // TODO: remote config
      );
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchBindOAuth;
